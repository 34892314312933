import React from 'react';
import { useQuery } from 'react-query';
import { getNavItemByWebId } from '../../services/service';
import { Button } from 'react-bootstrap';

function MediaFileButton({ webId = '', handleShow="" ,buttonText="ADD NEW FILE"}) {
    const getCookie = (name) => {
        const cookies = document.cookie.split(';');  
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith(name + '=')) {
                return cookie.substring(name.length + 1);
            }
        }
        return null;
    };

    const myCookieUserId = getCookie('userId');
    const websiteIds = webId;
    const webNavData = useQuery(['getNavItemByWebId', websiteIds,myCookieUserId], () =>
        getNavItemByWebId(websiteIds,myCookieUserId)
    );
console.log("webNavData cmpt",webNavData)
    const isGalleryActive = webNavData?.data?.data?.some(
        (item) =>  item.userrolenavisactive > 0
    );

    return (
        <>
            {isGalleryActive && (
                <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                    <Button onClick={(e)=>handleShow(e)} className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2">
                        {buttonText}
                    </Button>
                </div>
            )}
        </>
    );
}

export default MediaFileButton;
